import React, { useEffect, useState, useContext, MouseEvent } from 'react';
import * as Sentry from '@sentry/react';
import { Functions } from 'firebase/functions';
import { Firestore, doc } from 'firebase/firestore';
import { Auth } from 'firebase/auth';
import KlarnaOrder from '@klarna/payments/class/KlarnaOrder';
import KlarnaPaymentsSession from '@klarna/payments/class/KlarnaPaymentsSession';
import { ErrorPageData, TokenData } from '../types/types.d';
import { POPATypes } from '@api/popa/types';
import { CEETypes } from '@api/cee/types';
import CEE from '@api/cee';
import POPA from '@api/popa';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Logger } from '@utils/logger';
import { ErrorMessageConfig } from '@components/ErrorMessage';
import { FLOWS } from '../types/types.d';

interface AppContextValues {
	person?: CEETypes.Person;
	tokens?: TokenData;
	order?: KlarnaOrder;
	loading?: boolean;
	ceeOrder?: CEETypes.CEEOrder;
	paymentsSession?: KlarnaPaymentsSession;
	setTokens: (tokens: TokenData) => void;
	setOrder: (order: KlarnaOrder) => void;
	setCEEOrder: (ceeOrder: CEETypes.CEEOrder) => void;
	setPaymentsSession: (paymentsSession: KlarnaPaymentsSession) => void;
	products?: Record<string, CEETypes.ProductStatusV3>;
	firebaseAuth?: Auth;
	firebaseFirestore?: Firestore;
	firebaseFunctions?: Functions;
	showFAQ: (event: MouseEvent<HTMLAnchorElement>) => void;
	signOut: (redirectUrl: string) => Promise<void>;
	errorPage?: ErrorPageData;
	setErrorPage: (data: ErrorPageData | undefined) => void;
	errorMessageConfig?: ErrorMessageConfig;
	setFlow: (flow: FLOWS | null) => void;
	flow: FLOWS | null;
}

export const AppContext = React.createContext<AppContextValues>({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setTokens: (tokens) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setOrder: (order) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setCEEOrder: (ceeOrder) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setPaymentsSession: (paymentsSession) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	showFAQ: (event) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	signOut: async (redirectUrl) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setErrorPage: (data) => {},
	setFlow: (flow) => {},
	flow: null,
});

export const useApp = () => useContext(AppContext);

interface Props {
	tokens?: TokenData;
	setTokens: (tokens: TokenData) => void;
	// productStatus?: Record<string, CEETypes.ProductStatusV3>;
	firebaseAuth?: Auth;
	firebaseFirestore?: Firestore;
	firebaseFunctions?: Functions;
	showFAQ: (event: MouseEvent<HTMLAnchorElement>) => void;
	logger: Logger;
	children: React.ReactElement;
}

const AppProvider: React.FC<Props> = ({
	firebaseFirestore,
	firebaseAuth,
	firebaseFunctions,
	tokens,
	setTokens,
	showFAQ,
	logger,
	children,
}) => {
	const [person, setPerson] = useState<CEETypes.Person>();
	const [order, setOrder] = useState<KlarnaOrder>();
	const [ceeOrder, setCEEOrder] = useState<CEETypes.CEEOrder>();
	const [paymentsSession, setPaymentsSession] = useState<KlarnaPaymentsSession>();
	const [errorPage, setErrorPage] = useState<ErrorPageData>();
	const [loadingPerson, setLoadingPerson] = useState(true);
	const [flow, setFlow] = useState<FLOWS | null>(null);

	const refDoc =
		firebaseFirestore && firebaseAuth?.currentUser && tokens && tokens.CeePersonId
			? doc(firebaseFirestore, `user/${tokens.CeePersonId}`)
			: null;
	const [userDoc, userDocLoading, userDocError] = useDocument(refDoc);
	const products = userDoc?.data()?.products;

	if (userDocError) {
		logger.error('Error fetching user doc from firestore', userDocError);
	}

	const configRef =
		firebaseFirestore && firebaseAuth?.currentUser
			? doc(firebaseFirestore, `checkout/fotbollplay/config/error`)
			: null;
	const [errorConfigDoc, errorConfigLoading, errorConfigDocError] = useDocument(configRef);
	const errorMessageConfig = errorConfigDoc?.data() as ErrorMessageConfig;

	if (errorConfigDocError) {
		logger.error('Error fetching error config doc from firestore', errorConfigDocError);
	}

	useEffect(() => {
		async function asyncFetchPerson() {
			if (tokens?.accessToken) {
				setLoadingPerson(true);
				try {
					const newPerson = await CEE.getPerson(tokens.PersonId, tokens.accessToken);
					setPerson(newPerson);
				} catch (error) {
					Sentry.captureException(error);
				} finally {
					setLoadingPerson(false);
				}
			}
		}
		asyncFetchPerson();
	}, [tokens]);

	const signOut = async (redirectUrl: string) => {
		if (tokens) {
			const logoutUrl = await POPA.getRedirectToLogoutUrl(tokens.accessToken, redirectUrl);
			if (logoutUrl) {
				await firebaseAuth?.signOut();
				window.location.href = logoutUrl;
			}
		}
	};

	return (
		<AppContext.Provider
			value={{
				person,
				products,
				firebaseFirestore,
				firebaseAuth,
				firebaseFunctions,
				tokens,
				setTokens,
				order,
				setOrder,
				ceeOrder,
				setCEEOrder,
				paymentsSession,
				setPaymentsSession,
				showFAQ,
				signOut,
				errorPage,
				setErrorPage,
				errorMessageConfig,
				loading: userDocLoading || errorConfigLoading || loadingPerson,
				setFlow,
				flow,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppProvider;
