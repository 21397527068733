import React, { useState, useEffect } from 'react';
import { useApp } from '@contexts/app';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Container from '@components/Container';
import Card from '@components/Card';
import Button from '@components/Button';
import { H3 as PartH3, P } from '@components/Parts';
import AlreadySubscribedDialog from '../../components/AlreadySubscribedDialog';
import AccountFAQInfo from '@components/AccountFaqInfo';
import { useProduct } from '@contexts/product';
import { CEETypes } from '@api/cee/types';
import ErrorMessage from '@components/ErrorMessage';
import StartImage from './StartImage';
import useRedirect from '@hooks/useRedirect';
import { FLOWS } from '../../../../types/types.d';
import { Logger } from '@utils/logger';

const logger = new Logger('StartPage - H+ Subscription WIP');

const { REACT_APP_HIGHTLIGHTS_PLUS_V3_ID, REACT_APP_FOTBOLL_PLAY_URL } = process.env as Record<
	string,
	string
>;

const TEXT_CONTENT = `
	Ibland kommer livet emellan och hur mycket man än vill se hela matchen så måste man ibland göra något annat.
	Ett abonnemang på Highlights Plus passar för dig som inte kan se bortamatchen live, har svårt att hinna följa alla matcher på tv,
	vill se straffsituationen igen eller som vill följa flera lag samtidigt på ett smidigt och enkelt sätt!<br />
	<br />
	Höjdpunkterna finns tillgängliga via <a href="${REACT_APP_FOTBOLL_PLAY_URL}" target="_blank">Fotbollplay.se</a> eller i våra appar. Där kan du även slå på pushnotiser för matchhändelser så att du inte missar något mål.
`;

const ContentP = styled(P)`
	margin-top: 28px;
	margin-bottom: 28px;
	@media (max-width: 640px) {
		margin-top: 24px;
		margin-bottom: 24px;
	}
`;

const ButtonSeparator = styled.div`
	flex-shrink: 0;
	height: 18px;
	@media (max-width: 640px) {
		height: 12px;
	}
`;

const Bullets = styled.ul`
	padding: 20px 47px 20px 60px;
	background-color: #faf9fc;
	border: 1px solid #f6f4f9;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	font-size: 14px;
	text-align: left;
	// color: #341058;
	color: ${(props) => props.theme.accent};
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 38px;
	& li::marker {
		font-size: 18px;
	}
	@media (max-width: 640px) {
		padding: 18px 12px 20px 37px;
		margin-left: -20px;
		margin-right: -20px;
		font-size: 13px;
	}
`;

// TODO FIXME Improve and complete this when new highlights plus is available
// const getHasAccessToHighlightsPlus = (products?: Record<string, CEETypes.SubscriptionStatus>) => {
// 	const { HasAccessUntil } = (products && products[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION]) || {};
// 	return HasAccessUntil ? new Date(HasAccessUntil) > new Date() : false;
// };

const getHasAccessToHighlightsPlus = (productStatus: CEETypes.ProductStatusV3 | null): boolean => {
	// NOTE Any subscription is currently considered as access to Highlights Plus
	// TODO In future also check subscriptionStatus.includesHighlightsPlus
	return productStatus?.BillingType === 'Recurring' ?? false;
};

const getSubscriptionIsActive = (productStatus: CEETypes.ProductStatusV3 | null) => {
	// TODO Also check SubscriptionStartDate and SubscriptionNextRenewalDate once it's available
	return true;
};

export const H3 = styled(PartH3)`
	color: ${(props) => props.theme.accent};
`;

function StartPage(): JSX.Element {
	const { abbrv } = useParams<{
		abbrv: string;
	}>();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { errorMessageConfig, tokens, products } = useApp();
	const { redirectIfMissingProduct } = useRedirect();
	const { product, productId, setProductId, hasAccessToProduct } = useProduct();
	const [alreadySubscribedDialogOpen, setAlreadySubscribedDialogOpen] = useState(false);
	const productStatus =
		(productId && products && products[productId.toLocaleUpperCase()]) || null;

	const highlightPlusAccess = getHasAccessToHighlightsPlus(productStatus);
	const highlightPlusIsActive = getSubscriptionIsActive(productStatus);

	logger.debug('productId', productId);
	logger.debug('productStatus', productStatus);
	logger.debug('highlightsPlusAccess', highlightPlusAccess);
	logger.debug('highlightsPlusIsActive', highlightPlusIsActive);
	logger.debug('hasAccessToProduct', hasAccessToProduct);

	useEffect(() => {
		if (!productId) {
			setProductId(REACT_APP_HIGHTLIGHTS_PLUS_V3_ID);
		}
	}, [productId, setProductId]);

	// useEffect(() => {
	// 	redirectIfMissingProduct(FLOWS.SUBSCRIPTION, product);
	// }, [product, redirectIfMissingProduct]);

	useEffect(() => {
		if (
			!alreadySubscribedDialogOpen &&
			hasAccessToProduct &&
			highlightPlusAccess &&
			highlightPlusIsActive
		) {
			setAlreadySubscribedDialogOpen(true);
		}
	}, [
		hasAccessToProduct,
		alreadySubscribedDialogOpen,
		highlightPlusAccess,
		highlightPlusIsActive,
	]);

	// useEffect(() => {
	// 	if (
	// 		!loadedOnce.current &&
	// 		(isSubscriber || getHasAccessToHighlightsPlus(products)) &&
	// 		products?.[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION].Status !== 'Cancelled'
	// 	) {
	// 		loadedOnce.current = true;
	// 		setAlreadySubscribedDialogOpen(true);
	// 		// Set product id if it's missing, e.g. if user reloaded the page
	// 		if (!productId) {
	// 			setProductId(HIGHLIGHTS_PLUS_ID);
	// 		}
	// 	}
	// }, [isSubscriber, products, setProductId, productId]);

	const onClick = () => {
		const url = hasAccessToProduct
			? '/subscription/payment'
			: `/subscription/choose-team/${abbrv || ''}`;
		navigate(
			{
				pathname: url,
				search: searchParams.toString(),
			},
			{
				replace: true,
			}
		);
	};

	return (
		<>
			<Container role="document" className="StartPage">
				<ErrorMessage />
				<Card contentMaxWidth={769} paddingTop={40} flex>
					<StartImage />
					{!tokens?.accessToken && (
						<Button
							callToAction
							disabled={errorMessageConfig?.blocking}
							name="redirect-to-register"
							action={onClick}
						>
							Skapa konto
						</Button>
					)}
					<ButtonSeparator />
					{tokens?.accessToken && (
						<Button
							callToAction
							disabled={errorMessageConfig?.blocking}
							name="redirect-to-login"
							action={onClick}
						>
							Starta abonnemang
						</Button>
					)}
					{!tokens?.accessToken && (
						<Button
							disabled={errorMessageConfig?.blocking}
							name="redirect-to-login"
							action={onClick}
						>
							Logga in
						</Button>
					)}
					<ContentP dangerouslySetInnerHTML={{ __html: TEXT_CONTENT }} />
					<Bullets>
						<li>Höjdpunkter från Allsvenskans och Superettans 30 omgångar</li>
						<li>Pushnotiser direkt i app</li>
						<li>För en månadsavgift på 35 kr</li>
						<li>Utan bindningstid</li>
						<li>Överskottet från ditt abonnemang kan stötta din favoritklubb</li>
					</Bullets>
					<AccountFAQInfo showUseSameAccount />
				</Card>

				{alreadySubscribedDialogOpen && (
					<AlreadySubscribedDialog
						abbrv={abbrv}
						onClose={() => setAlreadySubscribedDialogOpen(false)}
						onChange={() => onClick()}
					/>
				)}
			</Container>
		</>
	);
}

export default StartPage;
