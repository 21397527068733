import React from 'react';
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeLogtail } from './utils/logtail';

// Init logging to Better Stack log tail
initializeLogtail();

Sentry.init({
	dsn: 'https://89dec87dfba44470bd445922ff4ac6c0@o543475.ingest.sentry.io/5688669',
	integrations: [Sentry.browserTracingIntegration()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: process.env.REACT_APP_SENTRY_ENV === 'production' ? 0.1 : 1.0,
	environment: process.env.REACT_APP_SENTRY_ENV,
	release: `sef-checkout@${process.env.REACT_APP_SENTRY_ENV}`,
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

reportWebVitals();
